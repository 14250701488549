@use "sass:meta" as ---g4o0m99d2w;/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */


.cloudTable-statusInfo {
  font-size: $euiFontSizeS;
  display: inline-block;
  padding: 0 0.4rem;
  line-height: 1.4rem;
  white-space: nowrap;
}

.cloudTable-statusInfo.is-ok {
  padding: 0;
}

.cloudTable-statusInfo.is-warning {
  color: $euiColorEmptyShade;
  background-color: $euiColorWarning;
}

.cloudTable-statusInfo.is-error {
  color: $euiColorEmptyShade;
  background-color: $euiColorDanger;
}

.cloudTable-statusInfo.is-stopped {
  background-color: $euiColorMediumShade;
}

;@include ---g4o0m99d2w.load-css("sass-embedded-legacy-load-done:413");