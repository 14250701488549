@use "sass:meta" as ---g4o0m99d2w;/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import './variables.scss';
@import './mixins/containers.scss';

html {
  @include indifferentRootContainer();
}

body {
  @include indifferentRootContainer();
  @include euiFont;
}

del {
  text-decoration: line-through;
}

;@include ---g4o0m99d2w.load-css("sass-embedded-legacy-load-done:384");