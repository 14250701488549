@use "sass:meta" as ---yacdf32n009;/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/inter_ui/Inter-UI-Thin-BETA.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-Thin-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/inter_ui/Inter-UI-ThinItalic-BETA.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-ThinItalic-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/inter_ui/Inter-UI-ExtraLight-BETA.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-ExtraLight-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/inter_ui/Inter-UI-ExtraLightItalic-BETA.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-ExtraLightItalic-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/inter_ui/Inter-UI-Light-BETA.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-Light-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/inter_ui/Inter-UI-LightItalic-BETA.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-LightItalic-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inter_ui/Inter-UI-Regular.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/inter_ui/Inter-UI-Italic.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/inter_ui/Inter-UI-Medium.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/inter_ui/Inter-UI-MediumItalic.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/inter_ui/Inter-UI-SemiBold.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/inter_ui/Inter-UI-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/inter_ui/Inter-UI-Bold.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/inter_ui/Inter-UI-BoldItalic.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/inter_ui/Inter-UI-ExtraBold.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/inter_ui/Inter-UI-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/inter_ui/Inter-UI-Black.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-Black.woff') format('woff');
}

@font-face {
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/inter_ui/Inter-UI-BlackItalic.woff2') format('woff2'),
    url('../fonts/inter_ui/Inter-UI-BlackItalic.woff') format('woff');
}

/* --------------------------------------------------------------------------
Single variable font.
Note that you may want to do something like this to make sure you're serving
constant fonts to older browsers:
html {
  font-family: 'Inter UI', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter UI var', sans-serif;
  }
}
BUGS:
- Safari 12.0 will default to italic instead of regular when font-weight
  is provided in a @font-face declaration.
  Workaround: Use 'Inter UI var alt' for Safari, or explicitly set
  `font-variation-settings:'slnt' DEGREE`.
*/
@font-face {
  font-family: 'Inter UI var';
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  src: url('../fonts/inter_ui/Inter-UI.var.woff2') format('woff2-variations'),
    url('../fonts/inter_ui/Inter-UI.var.woff2') format('woff2');
}

/* --------------------------------------------------------------------------
'Inter UI var alt' is recommended for Safari and Edge, for reliable italics.
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter UI var alt', sans-serif;
  }
}
*/
@font-face {
  font-family: 'Inter UI var alt';
  font-weight: 100 900;
  font-style: normal;
  /* stylelint-disable property-no-unknown */
  font-named-instance: 'Regular';
  /* stylelint-enable property-no-unknown */
  src: url('../fonts/inter_ui/Inter-UI-upright.var.woff2') format('woff2 supports variations(gvar)'),
    url('../fonts/inter_ui/Inter-UI-upright.var.woff2') format('woff2-variations'),
    url('../fonts/inter_ui/Inter-UI-upright.var.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter UI var alt';
  font-weight: 100 900;
  font-style: italic;
  /* stylelint-disable property-no-unknown */
  font-named-instance: 'Italic';
  /* stylelint-enable property-no-unknown */
  src: url('../fonts/inter_ui/Inter-UI-italic.var.woff2') format('woff2 supports variations(gvar)'),
    url('../fonts/inter_ui/Inter-UI-italic.var.woff2') format('woff2-variations'),
    url('../fonts/inter_ui/Inter-UI-italic.var.woff2') format('woff2');
}

/* ROBOTO MONO FONTS */
/* ROBOTO MONO FONTS */
/* ROBOTO MONO FONTS */
/* ROBOTO MONO FONTS */
/* ROBOTO MONO FONTS */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Mono Italic'), local('RobotoMono-Italic'), url('../fonts/roboto_mono/RobotoMono-Italic.ttf') format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Mono Bold Italic'), local('RobotoMono-BoldItalic'), url('../fonts/roboto_mono/RobotoMono-BoldItalic.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Mono'), local('RobotoMono-Regular'), url('../fonts/roboto_mono/RobotoMono-Regular.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Mono Bold'), local('RobotoMono-Bold'), url('../fonts/roboto_mono/RobotoMono-Bold.ttf') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

;@include ---yacdf32n009.load-css("sass-embedded-legacy-load-done:61");