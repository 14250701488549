@use "sass:meta" as ---yacdf32n009;/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import './variables.scss';

.card {
  background-color: $euiColorEmptyShade;
  border: 1px solid $euiColorLightShade;
  padding: $paddingBase;
  margin-bottom: $paddingLargest;

  &:last-child {
    margin-bottom: 0;
  }

  > .row {
    // Avoid excessive spacing
    margin-top: -$paddingBase;
    margin-bottom: -$paddingBase;
  }
}

;@include ---yacdf32n009.load-css("sass-embedded-legacy-load-done:59");